<template>
  <div class="cnccards">
      <div class="machinewrapper" v-for="machine of machines" :key="machine.Id">
         <dv-border-box-10>
           <div class="cncmachine">
              <div class="cncheader">
                <img :src="`https://www.szxinzhizao.com/images/cnc/cnc${machine.WorkStatus===2?'2':'0'}.png`"/>
                <div class="cnctitle">
                  <span style="font-weight:bold;color:#fdae6b;">{{machine.MachineNo}}</span>
                  <span>{{machine.MachineName}}</span>
                </div>
                <div class="cncstatus">
                  <div :class="`cncstatus${machine.WorkStatus!==2?'0':'1'}`"></div>
                  <div>{{machine.WorkStatus===2?'加工中':'空闲中'}}</div>
                </div>
              </div>
              <!-- <dv-decoration-9 style="width:50px;height:50px;">66%</dv-decoration-9> -->
              <!-- <dv-percent-pond :config="{value:80, lineDash:[0.25, 0.5, 0.25].map(l=>[20*l,3]).reduce((all, current) => [...all, ...current], []),colors: ['#01c4f9', '#c135ff']}" style="width:200px;height:100px;" /> -->
              <!-- <dv-capsule-chart :config="{data:[{name:machine.MachineNo,value:60}],unit: '分钟',showValue:true}" style="width:300px;height:200px" /> -->
              <div v-if="machine.WorkStatus===2" class="cncchucks">
                <div class="cncchuck" v-for="(item,index) of machine.WorkDetail.split('$')[0].split(',')" :key="index">
                  <div class="cncchuckno">
                    <span>{{index+1}}</span>
                  </div>
                  <div class="qcinfo">{{item}}</div>
                </div>
              </div>
              <div style="font-size:18px;display:flex; justify-content: center;align-items: center;" v-else>
                 <div style="padding-top:56px;">
                   暂无正在加工任务
                 </div>
              </div>
           </div>
          </dv-border-box-10>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  props: {
    machines: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      cards: []
    }
  },
  methods: {
    createData () {
      const { randomExtend } = this
      this.cards = new Array(18).fill(0).map((foo, i) => ({
        title: '机床' + (i),
        total: {
          number: [randomExtend(9000, 10000)],
          content: '{nt}',
          textAlign: 'right',
          style: {
            fill: '#ea6027',
            fontWeight: 'bold'
          }
        },
        num: {
          number: [randomExtend(30, 60)],
          content: '{nt}',
          textAlign: 'right',
          style: {
            fill: '#26fcd8',
            fontWeight: 'bold'
          }
        },
        ring: {
          series: [
            {
              type: 'gauge',
              startAngle: -Math.PI / 2,
              endAngle: Math.PI * 1.5,
              arcLineWidth: 13,
              radius: '80%',
              data: [
                { name: '资金占比', value: randomExtend(40, 60) }
              ],
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              pointer: {
                show: false
              },
              backgroundArc: {
                style: {
                  stroke: '#224590'
                }
              },
              details: {
                show: true,
                formatter: '{value}%',
                style: {
                  fill: '#1ed3e5',
                  fontSize: 20
                }
              }
            }
          ],
          color: ['#03d3ec']
        }
      }))
    },
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    }
  },
  mounted () {
    const { createData } = this
    createData()
    setInterval(this.createData, 30000)
  }
}
</script>

<style lang="less" scoped>
.cnccards {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  // justify-content:space-around;
  color: white;
  .machinewrapper{
    display: flex;
    // padding:10%;
    align-items: center;
    justify-content: center;
    flex-direction:row;
    padding: 5px 0;
    margin:0 2px;
    .cncmachine{
      display: flex;
      flex:1;
      flex-direction:column;
      align-items: center;
      justify-content: center;
      padding: 8px;
      .cncheader{
        display: flex;
        justify-content: center;
        align-items: center;
        .cnctitle{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        .cncstatus{
          padding: 10px 28px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .cncstatus0{
            background: red;
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }
          .cncstatus1{
            background: yellow;
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }
        }
      }
      .cncchucks{
        display: flex;
        flex-direction: column;
        padding: 8px;
        .cncchuck{
          display: flex;
          // border:1px solid red;
          padding: 3px;
          align-items: center;
          justify-content: center;
          // text-align: center;
          .cncchuckno{
            width:30px;
            height: 30px;
            background: #0f2b6f;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              text-align: center;
            }
          }
          .qcinfo{
            text-align: center;
            padding: 0 2px;
            margin: 8px;
            font-size: 8px;
          }
        }
      }
      .mno{
        flex: 1;
      }
    }
  }
}

</style>
