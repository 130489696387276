var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"camdsp-data-view"},[_c('dv-full-screen-container',[_c('top-header'),_c('div',{staticClass:"camdsp-main-content"},[_c('digital-flop',{attrs:{"flopdata":_vm.vm.ddatas}}),_c('div',{staticClass:"camdsp-block-left-right-content"},[_c('ranking-board'),_c('div',{staticClass:"camdsp-block-top-bottom-content"},[_c('div',{staticClass:"camdsp-block-top-content"},[_c('rose-chart',{attrs:{"rosechartoption":{option:{series:[{ type: 'pie', radius: '50%',roseSort: false, data: _vm.vm.rosechartdata,
                  insideLabel: {
                     show: false
                  },
                  outsideLabel: {
                    formatter: '{name} {percent}%',
                    labelLineEndLength: 20,
                    style: {
                      fill: '#fff'
                    },
                    labelLineStyle: {
                      stroke: '#1981f6'
                    }
                  },
                  roseType: true
                }],
                color: ['#03e9f4', 'blue', '#ff4411', '#1981f6', '#541200', '#801b00', 'white', '#ff1591', '#d86c00']
              }}}}),_c('water-level-chart'),_c('scroll-board',{attrs:{"scrolldata":{ config:{
              header: ['开始时间', '详情', '负责人', '模具及零件电极', '已用时'],
              data:_vm.vm.scrolldata,
              // index: true,
              align: ['center'],
              rowNum: 3,
              headerBGC: '#1981f6',
              headerHeight: 50,
              // oddRowBGC: 'rgba(0, 44, 81, 0.2)',
              oddRowBGC:'rgba(6, 30, 93, 0.5)',
              evenRowBGC: 'rgba(6, 30, 93, 0.7)'
              } }}})],1),_c('cards')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }