<template>
    <div id="cmmdsp-view">
        <dv-full-screen-container>
          <dv-border-box-11 title="质检中心">
             <top-header></top-header>
          <!-- <div class="clock">
            <clock/>
          </div> -->
             <div style="padding:0 16px;">
              <digital-flop :flopdata="vm.ddatas"/>
            </div>

          <div style="width:100%;height:260px;" class="cmm-center-content">
            <div class="cmm-bottom-content-left">
              <dv-charts :option="{title:{text: '检测合格率',style: {fill: '#fff'}},series: [{  center: ['50%', '55%'], type: 'gauge',data: [ { name: 'itemA', value: vm.hegerate, gradient: ['#ff0080', '#2196f3', 'green'], axisLabel: {formatter: '{value}%',style: {fill: '#fff'}},axisTick: {
              style: {
                stroke: '#fff'
              }
            },
            animationCurve: 'easeInOutBack' }],}]}" />
            </div>
            <div class="cmm-bottom-content-right">
              <dv-charts :option="option1" />
            </div>
          </div>
          <div class="main-content">
            <div class="block-left-right-content">
              <div class="clockwrapper">
                <clock/>
                <!-- <scrollBoard/> -->
                <dv-scroll-ranking-board :config="{data:vm.mcdatas}" style="width:500px;height:320px" />
              </div>
              <div class="block-top-bottom-content">
                <!-- <div class="block-top-content">
                </div> -->
                <cards :machines="vm.cmmmachines" />
              </div>
            </div>
          </div>

          </dv-border-box-11>
        </dv-full-screen-container>

    </div>
</template>

<script>
// import { changeDefaultConfig } from '@jiaminghi/charts'
import topHeader from './topHeader'
import digitalFlop from './digitalFlop'
import clock from '../Clock'
import cards from './cards'
let cmmdspinterval
export default {
  name: 'cmmdsp',
  components: {
    topHeader,
    digitalFlop,
    clock,
    cards
  },
  data () {
    return {
      id: '',
      code: '',
      intervaltime: 60, // s
      vm: {},
      optiony: {
        title: {
          text: '检测合格率',
          style: {
            fill: '#fff'
          }
        },
        series: [
          {
            type: 'gauge',
            data: [ { name: 'itemA', value: 55, gradient: ['#ff0080', '#2196f3', 'green'] } ],
            center: ['50%', '55%'],
            axisLabel: {
              formatter: '{value}%',
              style: {
                fill: '#fff'
              }
            },
            axisTick: {
              style: {
                stroke: '#fff'
              }
            },
            animationCurve: 'easeInOutBack'
          }
        ]
      },
      option1: {
        title: {
          text: '七天检测数量对比图',
          style: {
            fill: '#fff',
            fontSize: 17,
            fontWeight: 'bold',
            textAlign: 'center',
            textBaseline: 'bottom'
          }
        },
        legend: {
          show: false
        },
        xAxis: {
          data: [
            '星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'
          ],
          nameTextStyle: {
            fill: '#333',
            fontSize: 10
          },
          axisLabel: {
            show: false,
            style: {
              rotate: 20,
              textAlign: 'left',
              textBaseline: 'top',
              color: 'white'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            style: {
              stroke: '#fff',
              lineWidth: 3
            }
          }

        },
        yAxis: [
          {
            name: '电极数',
            nameTextStyle: {
              fill: '#fff',
              fontSize: 10
            },
            data: 'value',
            min: 0,
            max: 300,
            interval: 50,
            splitLine: {
              style: {
                lineDash: [3, 3],
                color: 'white'
              }
            },
            axisLabel: {
              formatter: '{value}个(支)',
              style: {
                stroke: '#fff'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              style: {
                stroke: '#fff',
                lineWidth: 3
              }
            }
          },
          {
            name: '零件',
            data: 'value',
            position: 'right',
            min: 0,
            max: 30,
            interval: 5,
            splitLine: {
              show: false
            },
            axisLabel: {
              formatter: '{value} 个'
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '零件',
            data: [
              175, 125, 90, 130, 45, 65, 65
            ],
            type: 'bar',
            gradient: {
              color: ['#37a2da', '#67e0e3']
            },
            animationCurve: 'easeOutBounce'
          },
          {
            name: '零件',
            data: [
              23, 18, 16, 14, 10, 8, 6
            ],
            type: 'line',
            yAxisIndex: 1,
            animationCurve: 'easeOutBounce'
          }
        ]
      }

    }
  },
  methods: {
    // nextnavigate () {
    //   const COUNT_TIMES = 5
    //   if (!this.timer) {
    //     this.count = COUNT_TIMES
    //     this.timer = setInterval(() => {
    //       if (this.count > 0 && this.count <= COUNT_TIMES) {
    //         this.count--
    //       } else {
    //         clearInterval(this.timer)
    //         this.timer = null
    //         this.$router.replace('/datacenter')
    //       }
    //     }, 1000)
    //   }
    // }

    getMachineByType (xid, xcode) {
      this.axios({ url: '/api/v1/dsp/cmmdspdata', method: 'get', params: { id: xid, checkcode: xcode } }).then(res => {
        this.$store.state.cid = { ...this.$store.state.cid, cid: xid }
        this.$store.state.checkcode = { ...this.$store.state.checkcode, checkcode: xcode }
        this.$store.state.cname = { ...this.$store.state.cname, cname: res.data.name }
        // this.$router.replace('/welcome')
        this.vm = res.data
      }).catch(errors => {
        if (errors.response.data) {
          alert(`${errors.response.data}`)
        } else {
          alert('网络错误！')
        }
      })
    },
    onchange (a) {
      console.log('aaa=>', a)
    }

  },
  created () {
    this.id = this.$route.params.id
    this.code = this.$route.params.code
    this.getMachineByType(this.id, this.code, 1)
    cmmdspinterval = window.setInterval(() => {
      if (this.intervaltime-- <= 0) {
        this.intervaltime = 60
        this.getMachineByType(this.id, this.code, 1)
      }
    }, 1000)
  },
  mounted () {
    this.$nextTick(res => {
      // console.log('in the mounted method')
      // this.id = this.$route.params.id
      // this.code = this.$route.params.code

      // console.log('cmmdsp===>id,code', this.id, this.code)
      // const cid = this.$store.state.cid.cid
      // const checkcode = this.$store.state.checkcode.checkcode
      // const cname = this.$store.state.cname.cname

      // console.log(cname, cid, checkcode)
      // if (cname && cid && checkcode) {
      //   this.companyname = cname
      //   this.nextnavigate()
      // } else {
      //   this.$router.replace('/identity')
      // }
    })
  },
  beforeDestroy () {
    window.clearInterval(cmmdspinterval)
  },

  watch: {
    // id (newValue, oldValue) {
    //   console.log('id=>newValue,oldValue', newValue, oldValue)
    // },
    // code (newValue, oldValue) {
    //   console.log('code=>newValue,oldValue', newValue, oldValue)
    // }
    // '$route': function (to, from) { ==>当前url改变时会触发此watch
    //   // 对路由变化作出响应...
    //   console.log('from==>to', from, to)
    // }
  }
}
</script>

<style lang="less">
 #cmmdsp-view {
  width: 100%;
  height: 100%;
  background-color: #000c15;
  color: #fff;
  overflow: hidden;
  // .clock{
  //  // position: absolute;
  //   // right: 0;
  //   // top:2px;
  // }
  #dv-full-screen-container {
    background-size: 100% 100%;
    box-shadow: 0 0 3px rgb(138, 170, 251);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    .clockwrapper{
      margin-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .clock{
        // background:#3ff7eb;
         width: 100%;
         border: 3px dashed #3ff7eb ;
      }
    }
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    height: 55%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
    .sysnotice{
      display: flex;
      width: 50%;
      height: 100%;
      background-color: rgba(6, 30, 93, 0.5);
      border-top: 2px solid rgba(1, 153, 209, .5);
      box-sizing: border-box;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      padding:15px;
      .ntitle{
        text-shadow: #fff;
        align-self: flex-start;
        font-size: 20px;
        font-weight: bolder;
        // font-family:'Yahei', 'Times New Roman', Times, serif;
      }
      p{
         text-indent:28px ;
         // font-weight: bold;
         word-spacing:1.5;
         line-height: 1.6;
      }
    }
  }

  // 中间图表框
  .cmm-center-content{
    width: 100%;
    display: flex;
    margin: 0 5px;
    .cmm-bottom-content-left{
      flex:2;
      display: flex;
      border:1px solid #5068a2;
    }
    .cmm-bottom-content-right{
      flex:8;
      border:1px solid #5068a2;
    }
  }
}
</style>
