<template>
    <div class="partecard">
      <div style="text-align:center;padding:18px;" v-if="stepList.length===0">
        暂无数据
      </div>
       <div v-else >
          <div style="text-align:center; padding:10px 0; background:#007bff;font-size:12px;color:white;"> 零件号: {{processes[0].MouldNo}}-{{processes[0].PartNo}}</div>
          <div style="margin-top:18px;">
             <vue-step  class="initHeight"  active-color="#007bff" style-type="style2" :now-step="current" direction="vertical" :step-list="stepList">
            
             </vue-step>
          </div>
        </div>
    </div>
</template>

<script>
import vueStep from 'vue-step'
export default {
  name: 'partecard',
  components:{
      vueStep
  },
  data () {
    return {
      pid: '',
      pno: '',
      current:0,
      processes:[],
      stepList: [],
      isloading:true
    }
  },
  methods: {
      getPartEcardInfo(pid,pno){
        this.axios({ url: '/api/v1/dsp/partecard', method: 'get', params: { pid: pid, pno: pno } }).then(res => {
         if(res.data){
           this.current=res.data.current-1
           this.processes=res.data.processes
           this.stepList=res.data.processes.map(el=>{
             if(el.ProduceStatus===3){
                return `${el.ProcessNo}【${el.ProcessProduceName}】【${el.Worker}】【${el.RealStartTime}~${el.RealFinishTime}】`
             }
              return `${el.ProcessNo}【${el.ProcessProduceName}】${el.ProduceStatus===4?'【等待返工】':''}`
           })
         }
      }).catch(errors => {
        if (errors.response.data) {
          alert(`${errors.response.data}`)
        } else {
          alert('网络错误！')
        }
      })
      }
  },
  mounted () {
     if(this.pid&&this.pno){
         this.getPartEcardInfo(this.pid,this.pno)
     }
  },
  created(){
    this.pid= this.$route.params.pid
    this.pno = this.$route.params.pno
  }
}
</script>

<style lang="less" scoped>
  .partecard{
    width: 100%;
    .initHeight{
      height: 460px;
    }
  }

 
   
</style>
