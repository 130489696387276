<template>
  <div id="top-header">
    <dv-decoration-8 :color="['rgb(138, 170, 251)','rgb(138, 170, 251)']" class="header-left-decoration" />
    <dv-decoration-5 :color="['rgb(138, 170, 251)','rgb(138, 170, 251)']" class="header-center-decoration" />
    <dv-decoration-8 :color="['rgb(138, 170, 251)','rgb(138, 170, 251)']" class="header-right-decoration" :reverse="true" />
    <!-- <div class="center-title">{{companyname}}CNC加工中心</div> -->
    <div class="center-title"></div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
  data () {
    return {
      companyname: this.$store.state.cname.cname
    }
  }
}
</script>

<style lang="less">
#top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
  }
  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }
  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
  }
}
</style>
