<template>
  <div id="mould-view">
    <dv-full-screen-container>
      <top-header />
      <div class="main-content">
        <digital-flop />
        <div class="block-left-right-content">
          <ranking-board />
          <div class="block-top-bottom-content">
            <div class="block-top-content">
              <!-- <rose-chart />
              <water-level-chart /> -->
              <div class="sysnotice">
                <div class="ntitle">
                    系统通知
                </div>
                <dv-loading v-if="mloading">Loading...</dv-loading>
                <p v-else>{{systemnotice}}</p>

              </div>
              <scroll-board />
            </div>
            <cards />
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from './topHeader'
import digitalFlop from './digitalFlop'
import rankingBoard from './rankingBoard'
// import roseChart from './roseChart'
// import waterLevelChart from './waterLevelChart'
import scrollBoard from './scrollBoard'
import cards from './cards'
export default {
  name: 'DataView',
  components: {
    topHeader,
    digitalFlop,
    rankingBoard,
    // roseChart,
    // waterLevelChart,
    scrollBoard,
    cards
  },
  data () {
    return {
      fullScreen: false,
      mloading: true,
      systemnotice: '暂无通知'
    }
  },
  methods: {
    // 全屏按钮\退出全屏按钮点击事件
    // fullScreenClick () {
    //   this.fullScreen = !this.fullScreen
    //   if (this.fullScreen) { // 当前要启动全屏
    //   // this.pageFullScreen();//执行全屏之后的一些操作
    //     this.setWindowFullScreen()
    //   } else { // 当前要退出全屏
    //   // this.pageExitFullScreen();//执行退出全屏之后的一些操作
    //     this.windowExitFullScreen()
    //   }
    // },
    // 启动全屏
    // setWindowFullScreen () {
    //   let docElm = document.documentElement
    //   if (docElm.requestFullscreen) {
    //     docElm.requestFullscreen()
    //   } else if (docElm.msRequestFullscreen) {
    //     docElm.msRequestFullscreen()
    //   } else if (docElm.mozRequestFullScreen) {
    //     docElm.mozRequestFullScreen()
    //   } else if (docElm.webkitRequestFullScreen) {
    //     docElm.webkitRequestFullScreen()
    //   }
    // },
    // 退出全屏
    // windowExitFullScreen () {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen()
    //   } else if (document.msExitFullscreen) {
    //     document.msExitFullscreen()
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen()
    //   } else if (document.webkitCancelFullScreen) {
    //     document.webkitCancelFullScreen()
    //   }
    // },
    // // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
    // checkFull () {
    // // 火狐浏览器
    //   let isFull = document.mozFullScreen ||
    //                 document.fullScreen ||
    //                 document.webkitIsFullScreen ||
    //                 document.webkitRequestFullScreen ||
    //                 document.mozRequestFullScreen ||
    //                 document.msFullscreenEnabled
    //   if (isFull === undefined) isFull = false
    //   return isFull
    // }
    nextnavigate () {
      const COUNT_TIMES = 15
      if (!this.timer) {
        this.count = COUNT_TIMES
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= COUNT_TIMES) {
            this.count--
          } else {
            clearInterval(this.timer)
            this.timer = null
            this.$router.replace('/welcome')
          }
        }, 2000)
      }
    }
  },
  mounted () {
    // 由于 全屏状态下 ，按 "Esc" 键退出全屏，浏览器监听不到 "Esc" 键的事件，所以需要通过浏览器窗口大小改变的事件去触发退出全屏事件
    // window.addEventListener('resize', function () {
    // // 增加 timer 定时器的原因是全屏和退出全屏的事件会触发两次窗口大小改变事件，做一次过滤
    //   if (this.timer) return
    //   this.timer = setTimeout(() => {
    //   // 如果是按 "Esc" 键退出全屏，窗口状态已经是非全屏，但是之前记录的状态还是全屏的状态
    //     if (!this.checkFull() && this.fullScreen) {
    //     // 退出全屏状态。。。
    //       this.fullScreen = false
    //       this.pageExitFullScreen()
    //     } else {
    //     // 进入全屏状态。。。

    //     }
    //     clearTimeout(this.timer)
    //     this.timer = null
    //   }, 10)
    // })
    // setTimeout(() => {
    //   this.setWindowFullScreen()
    // }, 100)
    this.$nextTick(res => {
      const cid = this.$store.state.cid.cid
      const checkcode = this.$store.state.checkcode.checkcode
      const cname = this.$store.state.cname.cname
      // console.log(cid, checkcode, cname)
      if (cname && checkcode && cid) {
        this.companyname = cname
        this.axios.get('/api/v1/knotice', { params: { id: cid, checkcode: checkcode } }).then(res => {
          this.systemnotice = res.data
          this.mloading = false
        }).catch(errors => {
          alert('获取通知失败')
        })
        this.nextnavigate()
      } else {
        this.$router.replace('/identity/')
      }
    })
  }
}
</script>

<style lang="less">
#mould-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('../../assets/imgs/bg.jpg');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    height: 55%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
    .sysnotice{
      display: flex;
      width: 50%;
      height: 100%;
      background-color: rgba(6, 30, 93, 0.5);
      border-top: 2px solid rgba(1, 153, 209, .5);
      box-sizing: border-box;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      padding:15px;
      .ntitle{
        text-shadow: #fff;
        align-self: flex-start;
        font-size: 20px;
        font-weight: bolder;
        // font-family:'Yahei', 'Times New Roman', Times, serif;
      }
      p{
         text-indent:28px ;
         // font-weight: bold;
         word-spacing:1.5;
         line-height: 1.6;
      }
    }
  }
}
</style>
