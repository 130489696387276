<template>
  <div class="camdsp-scroll-board">
    <dv-scroll-board :config="scrolldata.config"/>
  </div>
</template>

<script>
export default {
  name: 'camdsp-ScrollBoard',
  props: {
    scrolldata: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // config: {
      //   header: ['开始时间', '详情', '负责人', '详情', '已用时'],
      //   data: scrolldata,
      //   index: true,
      //   columnWidth: [50, 170, 300],
      //   align: ['center'],
      //   rowNum: 7,
      //   headerBGC: '#1981f6',
      //   headerHeight: 45,
      //   oddRowBGC: 'rgba(0, 44, 81, 0.8)',
      //   evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.camdsp-scroll-board {
  width: 50%;
  box-sizing: border-box;
  margin-left: 20px;
  height: 100%;
  overflow: hidden;
}
</style>
