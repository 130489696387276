<template>
  <div class="camdsp-data-view">
    <dv-full-screen-container>

      <top-header />

      <div class="camdsp-main-content">
        <digital-flop :flopdata="vm.ddatas" />

        <div class="camdsp-block-left-right-content">
          <ranking-board />

          <div class="camdsp-block-top-bottom-content">
            <div class="camdsp-block-top-content">
              <rose-chart :rosechartoption="{option:{series:[{ type: 'pie', radius: '50%',roseSort: false, data: vm.rosechartdata,
                    insideLabel: {
                       show: false
                    },
                    outsideLabel: {
                      formatter: '{name} {percent}%',
                      labelLineEndLength: 20,
                      style: {
                        fill: '#fff'
                      },
                      labelLineStyle: {
                        stroke: '#1981f6'
                      }
                    },
                    roseType: true
                  }],
                  color: ['#03e9f4', 'blue', '#ff4411', '#1981f6', '#541200', '#801b00', 'white', '#ff1591', '#d86c00']
                }}" ></rose-chart>

              <water-level-chart />

              <scroll-board :scrolldata="{ config:{
                header: ['开始时间', '详情', '负责人', '模具及零件电极', '已用时'],
                data:vm.scrolldata,
                // index: true,
                align: ['center'],
                rowNum: 3,
                headerBGC: '#1981f6',
                headerHeight: 50,
                // oddRowBGC: 'rgba(0, 44, 81, 0.2)',
                oddRowBGC:'rgba(6, 30, 93, 0.5)',
                evenRowBGC: 'rgba(6, 30, 93, 0.7)'
                } }"></scroll-board>
            </div>

            <cards />
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from './topHeader'
import digitalFlop from './digitalFlop'
import rankingBoard from './rankingBoard'
import roseChart from './roseChart'
import waterLevelChart from './waterLevelChart'
import scrollBoard from './scrollBoard'
import cards from './cards'
let camdspinterval
export default {
  name: 'CamDspDataView',
  components: {
    topHeader,
    digitalFlop,
    rankingBoard,
    roseChart,
    waterLevelChart,
    scrollBoard,
    cards
  },
  data () {
    return {
      id: '',
      code: '',
      intervaltime: 20, // s
      vm: {}
    }
  },
  methods: {
    getcamdspData (xid, xcode) {
      this.axios({ url: '/api/v1/dsp/camdspdata', method: 'get', params: { id: xid, checkcode: xcode } }).then(res => {
        this.$nextTick(nt => {
          this.vm = res.data
        })
      }).catch(errors => {
        if (errors.response.data) {
          alert(`${errors.response.data}`)
        } else {
          alert('网络错误！')
        }
      })
    }
  },
  created () {
    this.id = this.$route.params.id
    this.code = this.$route.params.code
    this.getcamdspData(this.id, this.code, 1)
    camdspinterval = window.setInterval(() => {
      if (this.intervaltime-- <= 0) {
        this.intervaltime = 20
        this.getcamdspData(this.id, this.code, 1)
      }
    }, 1000)
  },
  beforeDestroy () {
    window.clearInterval(camdspinterval)
  }
}
</script>

<style lang="less" scoped>
.camdsp-data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .camdsp-main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .camdsp-block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .camdsp-block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .camdsp-block-top-content {
    height: 55%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }
}
</style>
