import Vue from 'vue'
import VueRouter from 'vue-router'

// 引入组件
import dashboard from '@/components/datav/index.vue'
import identity from '@/components/identity/Index.vue'
import welcome from '@/components/welcome/Index.vue'
import mouldcenter from '@/components/mouldv/Index.vue'
import cncdsp from '@/components/cncdsp/Index.vue'
import cmmdsp from '@/components/cmmdsp/Index.vue'
import camdsp from '@/components/camdsp/Index.vue'
import ecard from '@/components/partecard/Index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { path: '/identity' }
  },
  {
    path: '/identity/:id',
    component: identity
  },
  {
    path: '/welcome',
    component: welcome
  },
  {
    path: '/datacenter',
    component: dashboard
  },
  {
    path: '/mouldcenter',
    component: mouldcenter
  },
  {
    path: '/cncdsp/:id-:rd/:code/:cid',
    component: cncdsp
  },
  {
    path: '/cmmdsp/:id-:rd/:code/:cid',
    component: cmmdsp
  },
  {
    path: '/camdsp/:id-:rd/:code/:cid',
    component: camdsp
  },
  {
    path:'/part/ecard/:pid/:pno',
    component:ecard
  }
]

var router = new VueRouter({
  routes
})
export default router
