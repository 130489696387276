<template>
  <div class="camdsp-ranking-board">
    <div class="camdsp-ranking-board-title"><span style="text-shadow:0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;">模具评审设计情况</span></div>
    <dv-scroll-ranking-board :config="config" />
  </div>
</template>

<script>
export default {
  name: 'CamDspRankingBoard',
  data () {
    return {
      config: {
        data: [
          {
            name: 'M20201(评审)',
            value: '55%'
          },
          {
            name: 'M20209(设计)',
            value: '79%'
          },
          {
            name: 'M20204(设计)',
            value: '18%'
          },
          {
            name: 'M20206(评审)',
            value: '62%'
          },
          {
            name: 'M20208(设计)',
            value: '100%'
          },
          {
            name: 'M20201(评审)',
            value: '55%'
          },
          {
            name: 'M20209(设计)',
            value: '79%'
          }
        ],
        rowNum: 7
      }
    }
  }
}
</script>

<style lang="less" scoped>
.camdsp-ranking-board {
  width: 20%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0px 30px;

  .camdsp-ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .dv-scroll-ranking-board {
    flex: 1;
  }
}
</style>
