<template>
  <div class="camdsp-rose-chart">
    <div class="camdsp-rose-chart-title"><span style="text-shadow:0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;">CAM模具中心</span></div>
    <dv-charts :option="rosechartoption.option" />
  </div>
</template>

<script>
export default {
  name: 'camdsp-RoseChart',
  props: {
    rosechartoption: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  methods: {
    // createData () {
    //   // const { randomExtend } = this

    //   // this.option = {
    //   //   series: [
    //   //     {
    //   //       type: 'pie',
    //   //       radius: '50%',
    //   //       roseSort: false,
    //   //       data: [
    //   //         { name: '张三', value: randomExtend(40, 70) },
    //   //         { name: '李四', value: randomExtend(20, 30) },
    //   //         { name: '五六', value: randomExtend(10, 50) },
    //   //         { name: '张三七', value: randomExtend(5, 20) },
    //   //         { name: '张三丰', value: randomExtend(40, 50) },
    //   //         { name: '李二狗', value: randomExtend(20, 30) },
    //   //         { name: '张全蛋', value: randomExtend(5, 10) },
    //   //         { name: '王二麻', value: randomExtend(20, 35) },
    //   //         { name: '赵马云', value: randomExtend(5, 10) }
    //   //       ],
    //   //       insideLabel: {
    //   //         show: false
    //   //       },
    //   //       outsideLabel: {
    //   //         formatter: '{name} {percent}%',
    //   //         labelLineEndLength: 20,
    //   //         style: {
    //   //           fill: '#fff'
    //   //         },
    //   //         labelLineStyle: {
    //   //           stroke: '#1981f6'
    //   //         }
    //   //       },
    //   //       roseType: true
    //   //     }
    //   //   ],
    //   //   color: ['#03e9f4', 'blue', '#ff4411', '#1981f6', '#541200', '#801b00', 'white', '#ff1591', '#d86c00']
    //   // }
    // },
    // randomExtend (minNum, maxNum) {
    //   if (arguments.length === 1) {
    //     return parseInt(Math.random() * minNum + 1, 10)
    //   } else {
    //     return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    //   }
    // }
  }
}
</script>

<style lang="less">
.camdsp-rose-chart {
  width: 30%;
  height: 100%;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;

  .camdsp-rose-chart-title {
    height: 50px;
    font-weight: bold;
    text-indent: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .dv-charts-container {
    height: calc(~"100% - 50px");
  }
}
</style>
