<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {

    }
  },
  methods: {

  },
  created () {
    // this.$store.state.pid = { ...this.$store.state.pid, pid: 123 }
    // // console.log('pid====>', this.$store.state.pid)
    // console.log('App.vue', this.$router.fullPath)
    // this.id = this.$route.query.id
  }
}
</script>

<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>
