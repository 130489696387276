import Vue from 'vue'
import router from './router'
import store from './store'
import axios from 'axios'
import App from './App.vue'
import VueParticles from 'vue-particles'
import './assets/common.less'

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
Vue.use(VueParticles)
Vue.prototype.axios = axios
axios.defaults.baseURL = 'https://identityserver.szxinzhizao.com'
// axios.defaults.baseURL = 'http://localhost:5000'
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('pid')) {
      config.headers.pid = localStorage.getItem('pid')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
