<template>
  <div class="cmmcards">
      <div class="machinewrapper" v-for="machine of machines" :key="machine.Id">
         <dv-border-box-10>
           <div class="cmmmachine">
              <div class="cmmheader">
                <img :src="`https://www.szxinzhizao.com/images/cmm/cmm${!machine.OnlineStatus?'0':(machine.WorkStatus===1?'1':(machine.WorkStatus===2?'2':'3'))}.png`"/>
                <div class="cmmtitle">
                  <span style="font-weight:bold;color:#fdae6b;">{{machine.MachineNo}}</span>
                  <span>{{machine.MachineName}}</span>
                </div>
                <div class="cmmstatus">
                  <div :class="`cmmstatus${machine.WorkStatus!==2?'0':'1'}`"></div>
                  <div>{{machine.WorkStatus==='2'?'检测中':'空闲中'}}</div>
                </div>
              </div>
              <!-- <dv-decoration-9 style="width:50px;height:50px;">66%</dv-decoration-9> -->
              <!-- <dv-percent-pond :config="{value:60, lineDash:[0.1, 0.5, 0.1].map(l => [100 * l, 6]).reduce((all, current) => [...all, ...current], []),colors: ['#01c4f9', '#c135ff']}" style="width:200px;height:30px;" /> -->
              <dv-capsule-chart :config="{data:[{name:machine.MachineNo,value:100}],unit: '%',showValue:true}" style="width:300px;" />
                  <div v-if="machine.WorkStatus===2" class="cncchucks">
                <div class="cmmchuck" v-for="(item,index) of machine.WorkDetail.split('$')[0].split(',')" :key="index">
                  <div class="cmmchuckno">
                    <span>{{index+1}}</span>
                  </div>
                  <div class="qcinfo">{{item}}</div>
                </div>
              </div>
              <div style="font-size:18px;display:flex; justify-content: center;align-items: center;" v-else>
                 <div style="margin-top:16px;">
                   暂无正在检测任务
                 </div>
              </div>
           </div>
          </dv-border-box-10>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  props: {
    machines: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      cards: []
    }
  },
  methods: {
    createData () {
      const { randomExtend } = this
      this.cards = new Array(18).fill(0).map((foo, i) => ({
        title: '机床' + (i),
        total: {
          number: [randomExtend(9000, 10000)],
          content: '{nt}',
          textAlign: 'right',
          style: {
            fill: '#ea6027',
            fontWeight: 'bold'
          }
        },
        num: {
          number: [randomExtend(30, 60)],
          content: '{nt}',
          textAlign: 'right',
          style: {
            fill: '#26fcd8',
            fontWeight: 'bold'
          }
        },
        ring: {
          series: [
            {
              type: 'gauge',
              startAngle: -Math.PI / 2,
              endAngle: Math.PI * 1.5,
              arcLineWidth: 13,
              radius: '80%',
              data: [
                { name: '资金占比', value: randomExtend(40, 60) }
              ],
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              pointer: {
                show: false
              },
              backgroundArc: {
                style: {
                  stroke: '#224590'
                }
              },
              details: {
                show: true,
                formatter: '{value}%',
                style: {
                  fill: '#1ed3e5',
                  fontSize: 20
                }
              }
            }
          ],
          color: ['#03d3ec']
        }
      }))
    },
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    }
  },
  mounted () {
    const { createData } = this
    createData()
    setInterval(this.createData, 30000)
  }
}
</script>

<style lang="less" scoped>
.cmmcards {
  display: flex;
  // height: 100%;
  width: 100%;
  flex-wrap: wrap;
  // justify-content:space-around;
  color: white;
  .machinewrapper{
    display: flex;
    // padding:10%;
    align-items: center;
    justify-content: center;
    flex-direction:row;
    padding: 5px 0;
    margin:0 2px;
    .cmmmachine{
      display: flex;
      flex:1;
      flex-direction:column;
      align-items: center;
      justify-content: center;
      padding: 8px;
      .cmmheader{
        display: flex;
        justify-content: center;
        align-items: center;
        .cmmtitle{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        .cmmstatus{
          padding: 10px 28px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .cmmstatus0{
            background: red;
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }
          .cmmstatus1{
            background: yellow;
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }
        }
      }
      .cmmchucks{
        display: flex;
        flex-direction: column;
        padding: 8px;
        .cmmchuck{
          display: flex;
          // border:1px solid red;
          padding: 3px;
          align-items: center;
          justify-content: center;
          // text-align: center;
          .cmmchuckno{
            width:50px;
            height: 50px;
            background: #0f2b6f;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              text-align: center;
            }
          }
          .qcinfo{
            text-align: center;
            padding: 0 2px;
            margin: 8px;
            font-size: 8px;
          }
        }
      }
      .mno{
        flex: 1;
      }
    }
  }
}

</style>
