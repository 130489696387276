<template>
    <div class="welcome">
         <vue-particles
            color="#ffffff"
            :particleOpacity="0.8"
            :particlesNumber="60"
            shapeType="circle"
            :particleSize="4"
            linesColor="#068cb3"
            :linesWidth="2"
            :lineLinked="true"
            :lineOpacity="0.5"
            :linesDistance="160"
            :moveSpeed="5"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
            class="particalbg">
        </vue-particles>
       <div class="wrapper">
         <div class="circle">
           <span class="daojishi">{{count}}</span>
         </div>
         <div class="companyname">
            {{companyname}}
         </div>
         <div class="bwelcome">
            热烈欢迎
         </div>
         <p class="zhizao">各位领导莅临指导</p>
       </div>
    </div>
</template>

<script>

export default {
  name: 'welcome',
  data () {
    return {
      companyname: '',
      count: ''
    }
  },
  methods: {
    nextnavigate () {
      const COUNT_TIMES = 5
      if (!this.timer) {
        this.count = COUNT_TIMES
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= COUNT_TIMES) {
            this.count--
          } else {
            clearInterval(this.timer)
            this.timer = null
            this.$router.replace('/datacenter')
          }
        }, 1000)
      }
    }

  },
  mounted () {
    this.$nextTick(res => {
      // console.log('welcome.vue===>this.$store.state.cname', this.$store.state.cname)
      const cid = this.$store.state.cid.cid
      const checkcode = this.$store.state.checkcode.checkcode
      const cname = this.$store.state.cname.cname
      console.log(cname, cid, checkcode)
      if (cname && cid && checkcode) {
        this.companyname = cname
        this.nextnavigate()
      } else {
        this.$router.replace('/identity')
      }
    })
  },
  watch: {
    checkcode (newValue, oldValue) {
      if (newValue.length === 6) {
        console.log(this.id)
        this.getProjectId(newValue)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .welcome{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background:  url('../../assets/imgs/bg.jpg') ;
    .wrapper{
      width: 50%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.2);
      align-self:center;
      flex-direction: column;
      position: absolute;
      border-radius: 18px;
      top:25%;
      left: 25%;
      z-index: 130 !important;
      .circle{
        position: absolute;
        right: 10px;
        top: 10px;
        width:28px;
        height: 28px;
        border-radius: 50%;
        color: red;
        background: darkgoldenrod;
        padding: 2px;
        .daojishi{
           font-size: 28px;
           color: white;
           padding-left:5px;

        }
      }
      .companyname{
        font-size: 36px;
        color: #ffffff;
        padding: 16px 0;
      }
      .bwelcome{
        font-size: 60px;
        font-weight: 600;
        color: red;
        padding-top: 16px;
        letter-spacing: 20px;
      }
      .zhizao{
         font-size: 60px;
        font-weight: bolder;
        font-family: 'Times New Roman', Times, serif,'楷体';
        color: yellow;
      }
    }
  }
</style>
