<template>
    <div class="identity">
        <vue-particles
            color="#ffffff"
            :particleOpacity="0.8"
            :particlesNumber="60"
            shapeType="circle"
            :particleSize="4"
            linesColor="#068cb3"
            :linesWidth="2"
            :lineLinked="true"
            :lineOpacity="0.5"
            :linesDistance="160"
            :moveSpeed="5"
            hoverMode="grab"
            :clickEffect="true"
            clickMode="push"
            class="particalbg">
        </vue-particles>
        <div class="login">
            <img class="logo" src="./logo.png">
            <input placeholder="请输入识别密码" ref="checkcode"  id="ck" class="checkcode" v-model="checkcode" type="password">
        </div>
    </div>
</template>

<script>

export default {
  name: 'identity',
  data () {
    return {
      checkcode: '',
      id: ''
    }
  },
  methods: {
    getProjectId (code) {
      this.axios({
        url: '/api/v1/isidentityclient',
        method: 'get',
        params: { id: this.id, checkcode: code }
      }).then(res => {
        this.$store.state.cid = { ...this.$store.state.cid, cid: this.id }
        this.$store.state.checkcode = { ...this.$store.state.checkcode, checkcode: code }
        this.$store.state.cname = { ...this.$store.state.cname, cname: res.data.name }
        this.$router.replace('/welcome')
      }).catch(errors => {
        if (errors.response.data) {
          alert(errors.response.data)
        }
      })
    }
  },
  mounted () {
    this.$nextTick(res => {
      const cid = this.$store.state.cid
      const checkcode = this.$store.state.checkcode
      const cname = this.$store.state.cname
      console.log('identity===>cname', cname.cname)
      if (cid.cid && checkcode.checkcode && cname) {
        this.$router.replace('/welcome')
      } else {
        document.getElementById('ck').focus()
        this.id = this.$route.params.id
      }
    })
  },
  watch: {
    checkcode (newValue, oldValue) {
      if (newValue.length === 6 && this.id) {
        console.log(this.id)
        this.getProjectId(newValue)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .identity{
      width: 100%;
      height: 100%;
      background: url('../../assets/imgs/bg.jpg');
      overflow: hidden;
      .login{
            position: absolute;
            top: 0;
            left: 36%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18%;
            flex-direction: column;
            z-index: 100;
            width: 25%;
            .logo{
              padding: 28px;
            }
            .checkcode{
                border: 2px solid black;
                border-radius: 5px;
                padding: 10px 18px;
                width: 100%;
                :focus{
                    border:none;
                }
            }
            .checkbutton{
                border: 1px solid blue;
                padding: 10px 8px;
                background: none;
                margin-left: 8px;
            }
     }
  }
</style>
