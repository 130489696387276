<template>
  <div class="camdsp-top-header">
    <!-- <dv-decoration-8 class="camdsp-header-left-decoration" />
    <dv-decoration-5 class="camdsp-header-center-decoration" /> -->
    <dv-decoration-10 style="width:50%;height:60px;transform:rotate(180deg)" />
    <dv-decoration-11 style="width:20%;height:60px;"><span style="font-weight:bold;font-size:26px;text-shadow:0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;animation:flash 3s infinite;">CAM模具中心</span></dv-decoration-11>
    <dv-decoration-10 style="width:50%;height:60px;" />
    <!-- <dv-decoration-8 class="camdsp-header-right-decoration" :reverse="true" />
    <div class="camdsp-center-title">CAM模具中心</div>-->
  </div>
</template>

<script>
export default {
  name: 'camdsp-TopHeader'
}
</script>

<style lang="less" scoped>
.camdsp-top-header {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  .camdsp-header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
  }

  .camdsp-header-left-decoration, .camdsp-header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .camdsp-center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 15px;
    background: rgba(255, 255, 255, .3);
    transform: translateX(-50%);
  }
}
@keyframes flash {
  100%{
    background-position: -400% 0;
  }

}
</style>
